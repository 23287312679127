<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable clearable placeholder="代理商" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select size="small"
					style="width: 260px;"
					v-if="isAgent"
					v-model="query.companyId"
					@change="companyChange"
					filterable
					clearable
					placeholder="企业"
					class="handle-select mr10"
				>
					<el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.departmentId" filterable clearable placeholder="部门" class="handle-select mr10">
					<el-option v-for="item in departments" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
				<el-button size="small" v-if="canAddDepartmentButton" type="primary" icon="el-icon-add  el-icon--right" class="handle-add mr100" @click="showForm">添加部门</el-button>
			</div>

			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }"
				:row-class-name="tableRowClassName"
			>
				<el-table-column prop="id" label="部门id" align="center"></el-table-column>
				<el-table-column prop="companyName" label="企业名称" align="center"></el-table-column>
				<el-table-column prop="name" label="部门名称" align="center"></el-table-column>
				<el-table-column prop="ownerName" label="负责人名称" align="center"></el-table-column>
				<el-table-column prop="ownerPhone" label="负责人账号" align="center"></el-table-column>
				<el-table-column label="操作" width="180" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row.id)">编辑</el-button>
						<el-button size="small" :disabled="canAddDepartment" type="text" icon="el-icon-delete" style="color: #f56c6c;" @click="handleDelete(scope.$index, scope.row.id)">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, prev, pager, next"
					:current-page="query.pageIndex"
					:page-size="query.pageSize"
					:total="pageTotal"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>

		<el-dialog :title="dialogtitle" v-model="addVisible" width="550px" @close="closeDialog('form')" :close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="代理" prop="agentId" v-if="isAdmin">
					<el-select size="small" @change="agentChange" v-model="form.agentId" filterable clearable placeholder="代理商" class="handle-select mr10" style="width: 100%;"  :disabled="companyDis">
						<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="企业" prop="companyId">
					<el-select size="small"
						v-model="form.companyId"
						@change="companyChange"
						:disabled="companyDis"
						filterable
						clearable
						placeholder="企业"
						class="handle-select mr10"
						style="width: 100%;"
					>
						<el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="部门名称" prop="name"><el-input size="small" v-model="form.name"></el-input></el-form-item>
				<!-- <el-form-item label="部门管理员" prop="department" v-if="canAddDepartmentButton"><el-input size="small" v-model="form.department"></el-input></el-form-item> -->

				<el-form-item v-if="canAddDepartmentButton" label="负责人名称" prop="ownerName"><el-input size="small" v-model="form.ownerName"></el-input></el-form-item>
				<el-form-item v-if="canAddDepartmentButton" label="负责人账号" prop="ownerPhone"><el-input size="small" v-model="form.ownerPhone"></el-input></el-form-item>
			</el-form>
			<div class="dialog-footer myRight">
				<el-button size="small" @click="closeDialog('form')">取消</el-button>
				<el-button size="small" v-if="addButton" type="primary" @click="addDepartment('form')">确定</el-button>
				<el-button size="small" v-if="updateButton" type="primary" @click="updateDepartment('form')">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { addDepartment, delDepartment, fetchDepartmentById, list, updateDepartment } from '../api/departmentsIndex.js';
import { fetchDepartment } from '../api/index.js';
import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
import { fetchAgent } from '../api/agentIndex';
export default {
	name: 'department',
	data() {
		return {
			query: {
				departmentId: '',
				pageIndex: 1,
				pageSize: 30
			},
			tableData: [],
			agents: [],
			companys: [],
			departments: [],
			multipleSelection: [],
			delList: [],
			editVisible: false,
			addVisible: false,
			isAdmin: false,
			isAgent: false,
			addButton: false,
			updateButton: false,
			companyDis: false,
			canAddDepartment: false,
			canAddDepartmentButton: true,
			pageTotal: 0,
			form: {
				agentId: '',
				companyId: '',
				name: '',
				ownerName: '',
				ownerPhone: ''
			},
			idx: -1,
			id: -1,
			loading: false,
			token: { Authorization: localStorage.getItem('token') },
			rules: {
				agentId: [{ required: true, message: '请选择代理', trigger: 'change' }],
				companyId: [{ required: true, message: '请选择企业', trigger: 'change' }],
				name: [{ required: true, message: '请输入部门名称', trigger: 'blur' }]
			},
			dialogtitle: '添加部门'
		};
	},
	created() {
		let role = localStorage.getItem('ms_role');

		if (role === 'normal') {
			this.canAddDepartmentButton = false;
			this.canAddDepartment = true;
		}
		if (role === 'department') {
			this.canAddDepartmentButton = false;
			this.canAddDepartment = true;
		}
		if (role === 'admin') {
			this.isAdmin = true;
			this.isAdmin2 = false;
		}
		if (role === 'agent' || role === 'admin') {
			this.isAgent = true;
		}
		if (role === 'company') {
			this.getData();
		}

		this.init();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		init() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
			});
			let data1 = {
				currentUserId: localStorage.getItem('user')
			};
			fetchDefaultCompanies(data1).then(res => {
				this.companys = res.data;
			});
			let data = {
				companyId: '',
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
			});
		},
		getData() {
			this.query.currentUserId = localStorage.getItem('user');
			list(this.query).then(res => {
				if (res.code === 200) {
					this.tableData = res.data.list;
					this.pageTotal = res.data.total;
					let data = {
						companyId: this.query.companyId,
						currentUserId: localStorage.getItem('user')
					};
					fetchDepartment(data).then(res => {
						this.departments = res.data;
					});
				} else {
					this.$message.error(res.message);
				}
			});
		},
		agentChange(agentId) {
			this.query.agentId = agentId;
			this.query.companyId = '';
			this.query.departmentId = '';
			this.query.userId = '';
			this.form.agentId = agentId;
			this.form.companyId = '';
			this.form.departmentId = '';
			this.form.userId = '';
			if (agentId) {
				fetchCompanyByAgentId(agentId).then(res => {
					this.companys = res.data;
				});
			}
		},
		companyChange(companyId) {
			this.query.companyId = companyId;
			this.query.departmentId = '';
			this.query.userId = '';
			this.form.companyId = companyId;
			this.form.departmentId = '';
			this.form.userId = '';
			let data = {
				companyId: companyId,
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
				this.query.pageIndex = 1;
				this.getData();
			});
		},
		showForm() {
			this.dialogtitle = '添加部门';
			this.addVisible = true;
			this.addButton = true;
			this.updateButton = false;
		},
		addDepartment(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true;
					addDepartment(this.form).then(res => {
						if (res.code === 200) {
							this.$message.success('添加成功');
							this.query.agentId = this.form.agentId;
							this.query.companyId = this.form.companyId;
							this.getData();
						} else {
							this.$message.error(res.message);
						}
						this.addVisible = false;
					});
				}
			});
		},
		// 编辑操作
		handleEdit(index, id) {
			this.dialogtitle = '编辑部门';
			this.addVisible = true;
			this.idx = index;
			this.addButton = false;
			this.companyDis = true;
			this.updateButton = true;
			// 获取详情
			fetchDepartmentById(id).then(res => {
				this.form.agentId = res.data.agentId;
				this.form.companyId = res.data.companyId;
				this.form.id = id;
				this.form.name = res.data.name;
				this.form.ownerName = res.data.ownerName;
				this.form.ownerPhone = res.data.ownerPhone;
			});
		},
		updateDepartment(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true;
					updateDepartment(this.form).then(res => {
						if (res.code === 200) {
							this.$message.success('修改成功');
							this.getData();
							this.init();
						} else {
							this.$message.error(res.message);
						}
						this.addVisible = false;
					});
				}
			});
		},
		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getData();
		},
		// 删除操作
		handleDelete(index, id) {
			// 二次确认删除
			this.$confirm('确定要删除吗？', '提示', {
				type: 'warning'
			})
				.then(() => {
					delDepartment(id).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.pageTotal = this.pageTotal - 1;
							this.tableData.splice(index, 1);
						} else {
							this.$message.error(res.message);
						}
					});
				})
				.catch(() => {});
		},
		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getData();
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		closeDialog(formName) {
			this.addVisible = false;
			this.companyDis = false;
			(this.form = {
				companyId: '',
				name: '',
				ownerName: '',
				ownerPhone: ''
			}),
				this.resetForm(formName);
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

/* a:hover{background: #66b1ff} */
.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 14px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
</style>
<style scoped>
.el-dialog__body {
	padding: 30px 20px 60px;
}
</style>
