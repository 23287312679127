import request from '../utils/request';
import qs from "qs";

//蜗牛添加停复机
export const SnailStopOrOpen = param => {
	return request.post('/api/Snail/stopOrOpen', qs.stringify(param))
};


//蜗牛停复机记录
export const stopOrOpenRecordList = param => {
	return request.post('/api/Snail/stopOrOpenRecord', qs.stringify(param))
};

//蜗牛停复机下载
export const WNdownloadStopOrOpenRecord = param => {
	return request.post('/api/Snail/downloadStopOrOpenRecord', qs.stringify(param), {
		responseType: 'blob'
	})
};
//蜗牛话单异常列表
export const SnailErrRecord = param => {
	return request.post('/api/Snail/ErrRecord', qs.stringify(param))
};

//蜗牛批量加白手动
export const BatchWhitening = param => {
	return request.post('/api/Snail/BatchWhitening', qs.stringify(param))
};


//蜗牛加白记录
export const getContrastInfo = data => {
	return request.get('/api/Snail/getContrastInfo?importFileName=' + data.importFileName +
		'&&pageIndex=' + data.pageIndex + '&&pageSize=' + data.pageSize)

};
//蜗牛开户进度
export const openAcctSchedule = param => {
	return request.post('/api/Snail/openAcctSchedule', qs.stringify(param))
};
//蜗牛限制返档
export const reverseFile = param => {
	return request.post('/api/Snail/ReverseFile', qs.stringify(param))
};

//蜗牛复机设置
export const RecordingRecover = param => {
	return request.post('/api/Snail/RecordingRecover', qs.stringify(param))
};
