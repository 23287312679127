<template>
	<div>
		<div class="container">
			<div>
				<el-form :model="query">
					<el-form-item label="话单时间">
						<el-date-picker size="small" type="date" placeholder="选择日期" v-model="query.date1" style="width: 200px;margin-right: 10px;" @change="getTime"></el-date-picker>
						<!-- <el-button size="small" type="primary" class="m_l_10" @click="getBill">生成话单</el-button> -->
						<el-button size="small" type="primary" class="m_l_10" @click="getfindSnailsRecord">查询</el-button>
					</el-form-item>
				</el-form>
			</div>

			<el-table
				style="margin-top:20px"
				v-loading="loading"
				:data="tableData"
				border
				class="table"
				header-cell-class-name="table-header"
				:row-style="{ height: '20px' }"
				:header-cell-style="{ 'text-align': 'center' }"
				:cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName"
			>
				<el-table-column v-if="false" prop="path" align="center"></el-table-column>
				<el-table-column prop="fileName" label="导入模板名称" align="center"></el-table-column>
				<el-table-column prop="time" label="导入时间" align="center"></el-table-column>
				<el-table-column prop="status" label="状态" align="center"></el-table-column>
				<el-table-column prop="operate" label="操作" align="center">
					<template #default="scope">
						<el-button size="small"
							:disabled="scope.row.status === '查询中' || scope.row.status === '查询失败'"
							type="text"
							icon="el-icon-download"
							@click="download(scope.$index, scope.row)"
						>
							下载
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="validate">
			<el-dialog title="文件上传" v-model="upload" width="500px" @close="closeUpload()" :close-on-click-modal="false">
				<el-upload
					ref="upload"
					action="/api/invoke/upload"
					:headers="token"
					:limit="1"
					:on-success="uploadSuccess"
					:on-preview="handlePreview"
					:on-remove="handleRemove"
					:auto-upload="false"
					:data="uploadData"
				>
					<el-button size="small" type="primary">选择文件</el-button>
					<div class="el-upload__tip">
						<p>1.单次最多支持一万个号码导入</p>
						<p>2.导入模板表头不可修改</p>
						<p>3.导入后会生成一条查询结果数据记录，状态为查询中，待状态变更为已完成，则可操作下载，下载查询结果</p>
					</div>
				</el-upload>
				<el-button size="small" class="btn" type="success" v-loading.fullscreen.lock="loading" @click="submitUpload">确定上传</el-button>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import { getSnailsRecord, findSnailsRecord, getData } from '../api/reportNumber.js';

export default {
	name: 'validate',
	data() {
		return {
			query: {
				date1: ''
			},
			fileList: [],
			types: [],
			tableData: [],
			loading: false,
			canDownload: true,
			multipleSelection: [],
			editVisible: false,
			batchVisible: false,
			updateButton: false,
			pageTotal: 0,
			form: {
				date1: ''
			},
			risk: {},
			idx: -1,
			id: -1,
			show: false,
			upload: false,
			token: { Authorization: localStorage.getItem('token') },
			path: process.env.BASE_URL,
			time: '',
			uploadData:{
				currentUserId:localStorage.getItem('user')
			}
		};
	},
	created() {
		this.getDate();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		getTime() {
			if (this.query.date1) {
				this.time = this.toDateTimeStr(this.query.date1);
			} else {
				this.time = this.query.date1;
			}
		},
		getfindSnailsRecord() {
			let data = {
				time: this.time,
				currentUserId:localStorage.getItem('user')
			};
			findSnailsRecord(data).then(res => {
				if (res.code == 200) {
					this.tableData = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		getDate() {
			let now = new Date();
			let year = now.getFullYear(); //得到年份
			let month = now.getMonth(); //得到月份
			let date = now.getDate(); //得到日期
			let hour = ' 00:00:00'; //默认时分秒 如果传给后台的格式为年月日时分秒，就需要加这个，如若不需要，此行可忽略
			month = month + 1;
			month = month.toString().padStart(2, '0');
			date = date.toString().padStart(2, '0');
			this.query.date1 = `${year}-${month}-${date}`; //
			this.time = `${year}-${month}-${date}`;
			this.getfindSnailsRecord();
		},
		toDateTimeStr(nS) {
			let date = new Date(nS);
			let YY = date.getFullYear() + '-';
			let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
			let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
			let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
			let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
			let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
			return YY + MM + DD;
		},
		download(index, row) {
			getData(row.path).then(res => {
				const link = document.createElement('a');
				let blob = new Blob([res], { type: 'text/plain' });
				link.style.display = 'none';
				link.href = URL.createObjectURL(blob);
				link.setAttribute('download', row.fileName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			});
		},
		// 获取数据
		uploadExcel() {
			this.upload = true;
		},

		showForm() {
			this.updateButton = false;
		},
		submitUpload() {
			if (this.$refs.upload.uploadFiles[0]) {
				this.loading = true;
				this.$refs.upload.submit();
			} else {
				this.$message.error('请选择上传文件');
			}
		},

		// 多选操作
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getData();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.getData();
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		closeDialog(formName) {
			(this.form = {
				companyId: '',
				departmentId: '',
				account: '',
				password: '',
				name: ''
			}),
				this.resetForm(formName);
		},
		closeUpload() {
			this.$refs.upload.clearFiles();
		},
		uploadSuccess(success) {
			setTimeout(() => {
				this.loading = false;
				this.$refs.upload.clearFiles();
				this.upload = false;
			}, 2000);
			if (success.code === 200) {
				this.tableData = success.data;
				this.$message.success('上传成功');
			} else {
				this.$message.error(success.message);
			}
		},
		open(message) {
			this.$alert(message, '导入失败', {
				confirmButtonText: '确定'
			});
		}
	}
};
</script>

<style scoped>
/* a:hover {
	background: #66b1ff;
} */
.handle-select {
	width: 120px;
}

.table {
	width: 100%;
	font-size: 14px;
}

.mr10 {
	width: 400px;
	margin-right: 10px;
}
.grid-content {
	display: flex;
	align-items: center;
	height: 100px;
}
/deep/ .el-col-16 {
	flex: 0 0 59.9%;
}
.grid-cont-right {
	flex: 1;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	color: #999;
}
.grid-num {
	font-size: 30px;
	font-weight: bold;
}

.grid-con-icon {
	font-size: 50px;
	width: 100px;
	height: 100px;
	text-align: center;
	line-height: 100px;
	color: #fff;
}
.grid-con-1 .grid-con-icon {
	background: linear-gradient(#ffb199, #ff0844);
}
.new {
	color: #b5b4b4;
	font-size: 12px;
}
.grid-con-1 .grid-num {
	color: #4f7afd;
}

.grid-con-2 .grid-con-icon {
	background: linear-gradient(#48c6ef, #6f86d6);
}

.grid-con-2 .grid-num {
	color: #4f7afd;
}

.grid-con-3 .grid-con-icon {
	background: linear-gradient(#e5b2ca, #7028e4);
}

.grid-con-3 .grid-num {
	color: #4f7afd;
}
.grid-con-4 .grid-con-icon {
	background: linear-gradient(#00c6fb, #005bea);
}

.grid-con-4 .grid-num {
	color: #4f7afd;
}
.grid-con-5 .grid-con-icon {
	background: linear-gradient(#89f7fe, #66a6ff);
}

.grid-con-5 .grid-num {
	color: #4f7afd;
}
.down {
	border: 1px solid #dcdfe6;
	background: #4f7afd;
	color: #ffffff;
	transition: 0.1s;
	font-weight: 500;
	padding: 8px 16px;
	font-size: 14px;
	border-radius: 4px;
	margin-left: 10px;
	display: inline-block;
	width: 88px;
}
</style>
<style>
.validate .el-upload {
	width: 100% !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
}
.validate .btn {
	background-color: #4f7afd;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border: none;
	margin-left: 80%;
}

</style>
