<template>
	<div>
		<div class="container"> 
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable clearable placeholder="代理商" class="handle-select mr10" style="width: 260px">
					<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.companyId" @change="companyChange" filterable clearable placeholder="企业" class="handle-select mr10" style="width: 260px">
					<el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.departmentId" filterable clearable placeholder="部门" class="handle-select mr10" style="width: 180px">
					<el-option v-for="item in departments" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-model.trim="query.account" clearable placeholder="员工手机号" class="handle-input mr10" style="width: 180px"></el-input>
				<el-button size="small" type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
			</div>

			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:header-cell-style="{ 'text-align': 'center' }"
				@selection-change="handleSelectionChange"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName"
			>
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="id" width="240" label="id" align="center"></el-table-column>
				<el-table-column prop="account" label="手机号" align="center"></el-table-column>
				<el-table-column prop="name" label="姓名" align="center"></el-table-column>
				<el-table-column prop="companyName" width="340" label="公司" align="center"></el-table-column>
				<el-table-column prop="departmentName" label="部门" align="center"></el-table-column>
				<el-table-column prop="createDate" width="180" label="分配时间" align="center"></el-table-column>
				<el-table-column prop="status" label="状态" align="center">
					<template #default="scope">
						<el-tag :type="scope.row.status === '已分配' ? 'success' : scope.row.status === '已撤销' ? 'danger' : 'warning'">{{ scope.row.status }}</el-tag>
					</template>
				</el-table-column>

				<el-table-column label="操作" width="180" align="center">
					<template #default="scope">
						<el-button size="small" v-if="scope.row.status === '已分配'" type="text" icon="el-icon-edit" @click="cancel(scope.$index, scope.row.id)">撤销</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex"
					:page-sizes="[100, 200, 300, 400]"
					:page-size="query.pageSize"
					:total="pageTotal"
					@size-change="handleSizeChange"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { fetchDepartment } from '../api/index.js';
import { userSerial, cancel } from '../api/serialIndex.js';
import { fetchAgent, fetchInitAgent } from '../api/agentIndex.js';
import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
export default {
	name: 'user',
	data() {
		return {
			query: {
				companyId: '',
				departmentId: '',
				pageIndex: 1,
				pageSize: 30
			},
			fileList: [],
			agents: [],
			companys: [],
			departments: [],
			tableData: [],
			multipleSelection: [],
			delList: [],
			editVisible: false,
			batchVisible: false,
			riskVisible: false,
			updateButton: false,
			showAgent: false,
			agentDis: false,
			isAdmin: false,
			isAgent: false,
			companyDis: false,
			pageTotal: 0,
			form: {
				role: '',
				agentId: '',
				invalidateDate: 0,
				invalidateDateIncrease: 0
			},
			risk: {
				num: 1
			},
			rules: {
				checked: [{ required: true, message: '请选择角色', trigger: 'change' }]
			},
			idx: -1,
			id: -1,
			show: false,
			upload: false,
			loading: false,
			invalidateDate: 0,
			token: { Authorization: localStorage.getItem('token') }
		};
	},
	created() {
		const role = localStorage.getItem('ms_role');
		if (role === 'admin') {
			this.isAdmin = true;
			this.agentDis = true;
			this.companyDis = true;
		}
		if (role === 'agent') {
			this.isAgent = true;
			this.companyDis = true;
		}

		this.init();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		init() {
			let data ={
				currentUserId:localStorage.getItem('user')
			}
			fetchInitAgent(data).then(res => {
				if (res.data) {
					this.invalidateDate = res.data.validCallTime;
				}
			});
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
			});
			let data2 ={
				companyId:'',
				currentUserId:localStorage.getItem('user')
			}
			fetchDepartment(data2).then(res => {
				this.departments = res.data;
			});
			
			fetchDefaultCompanies(data).then(res => {
				this.companys = res.data;
			});
		},
		getData() {
			userSerial(this.query).then(res => {
				if (res.code === 200) {
					this.tableData = res.data.list;
					this.pageTotal = res.data.total;
				} else {
					this.$message.error('请输入查询条件');
				}
			});
		},
		agentChange(agentId) {
			this.query.agentId = agentId;
			this.query.companyId = '';
			this.query.departmentId = '';
			this.query.userId = '';
			this.form.agentId = agentId;
			this.form.companyId = '';
			this.form.departmentId = '';
			this.form.userId = '';
			if (agentId) {
				fetchCompanyByAgentId(agentId).then(res => {
					this.companys = res.data;
				});
			}
		}, 
		
		companyChange(companyId) { 
			this.query.companyId = companyId;
			this.query.departmentId = '';
			this.query.userId = '';
			this.form.companyId = companyId;
			this.form.departmentId = '';
			this.form.userId = '';
			let data = {
				companyId: companyId,
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
				this.query.pageIndex=1;
				this.getData();
			});
		},
		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getData();
		},
		showForm() {
			this.updateButton = false;
		},

		// 多选操作
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		// 撤销操作
		cancel(index, id) {
			// 二次确认删除
			this.$confirm('确定要撤销吗？', '提示', {
				type: 'warning'
			})
				.then(() => {
					cancel(id).then(res => {
						if (res.code == 200) {
							this.$message.success('撤销成功成功');
							this.getData();
						} else {
							this.$message.error(res.message);
						}
					});
				})
				.catch(() => {});
		},
		riskEdit(index, id) {
			this.risk.userId = id;
			this.idx = index;
			this.riskVisible = true;
		},
		// 编辑操作
		batchEdit() {
			let str = '';
			this.delList = this.delList.concat(this.multipleSelection);
			for (let i = 0; i < this.multipleSelection.length; i++) {
				str += this.multipleSelection[i].id + ',';
			}
			if (str === '') {
				this.$message.error(`请选择员工`);
				return;
			}
			this.batchVisible = true;
			this.updateButton = true;
		},

		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getData();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.getData();
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		closeDialog(formName) {
			(this.form = {
				companyId: '',
				departmentId: '',
				account: '',
				password: '',
				name: ''
			}),
				this.resetForm(formName);
		},
		closeUpload() {
			this.$refs.upload.clearFiles();
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}
.down {
	border: 1px solid #dcdfe6;
	background: #4f7afd;
	color: #ffffff;
	transition: 0.1s;
	font-weight: 500;
	padding: 8px 16px;
	font-size: 14px;
	border-radius: 4px;
	margin-left: 10px;
	display: inline-block;
	width: 88px;
}

.handle-select {
	width: 120px;
}

.handle-input {
	width: 300px;
	display: inline-block;
}
.table {
	width: 100%;
	font-size: 14px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}
.grid-con-icon {
	font-size: 50px;
	width: 100px;
	height: 100px;
	text-align: center;
	line-height: 100px;
	color: #fff;
}
.grid-cont-right {
	flex: 1;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	color: #999;
}
.grid-content {
	display: flex;
	align-items: center;
	height: 100px;
}
</style>
