import request from '../utils/request';
import qs from "qs";

export const fetchData = param => {
	return request.post('/api/customer/list', qs.stringify(param))
};
export const customerFile = param => {
	return request.post('/api/customer/customerFile', qs.stringify(param))
};
export const getNormalFile = param => {
	return request.post('/api/customer/getNormalFile', qs.stringify(param))
};
export const getByCustomerFileId = param => {
	return request.post('/api/customer/getByCustomerFileId', qs.stringify(param))
};
export const getCustomer = param => {
	return request.post('/api/customer/getCustomer', qs.stringify(param))
};
export const findByQuery = param => {
	return request.post('/api/customer/findByQuery', qs.stringify(param))
};
export const fetchDataById = customerId => {
	return request.get('/api/customer/fetchDataById?customerId=' + customerId)
};
export const getCallStatus = userName => {
	return request.get('/api/socket/getCallStatus?userName=' + userName)
};
export const addCustomer = param => {
	return request.post('/api/customer/add', qs.stringify(param))
};

export const countCustomer = param => {
	return request.post('/api/customer/countCustomer', qs.stringify(param))
};

export const updateCustomer = param => {
	return request.post('/api/customer/update', param, {headers: {	'Content-Type': 'application/json'}})
};
export const setIntervals = param => {
	return request.post('/api/user/setIntervals', qs.stringify(param))
};
export const updateCustomerFile = param => {
	return request.post('/api/customer/updateCustomerFile', qs.stringify(param))
};
export const deleteCompletedFile = param => {
	return request.post('/api/customer/deleteCompletedFile', qs.stringify(param))
};
export const deleteFile = param => {
	return request.post('/api/customer/deleteFile', qs.stringify(param))
};

export const findNormal = param => {
	return request.post('/api/customer/findNormal', qs.stringify(param))
};
export const delCustomer = customerId => {
	return request.get('/api/customer/delete?customerId=' + customerId)
};


export const batchDelete = param => {
	return request.post('/api/customer/batchDelete', qs.stringify(param))
};

export const socketSend = param => {
	// let ip = localStorage.getItem('AppInfoIp')
	// if (ip == '172.26.55.66') {
	// 	return request.post('/api/socket/send', qs.stringify(param))
	// } else if (ip == "172.26.56.50") {
	// 	return request.post('/cysacyod/socket/send', qs.stringify(param))
	// }else{
	// 	return request.post('/api/socket/send', qs.stringify(param))
	// }

	// if (ip == '172.26.55.66') {
	// 	return request.post('/gat/socket/send', qs.stringify(param))
	// } else if (ip == "172.26.56.54") {
	// 	return request.post('/cysacyod/socket/send', qs.stringify(param))
	// }else{
	// 	return request.post('/api/socket/send', qs.stringify(param))
	// }
	
	let url = localStorage.getItem('AppInfoIp') + '/socket/send'
	return request.post(url, qs.stringify(param))

};

export const socketIntention = param => {
	// let ip = localStorage.getItem('AppInfoIp')
	// if (ip == '172.26.55.66') {
	// 	return request.post('/api/socket/intention', qs.stringify(param))
	// } else if (ip == "172.26.56.50") {
	// 	return request.post('/cysacyod/socket/intention', qs.stringify(param))
	// }else{
	// 	return request.post('/api/socket/intention', qs.stringify(param))
	// }
	// if (ip == '172.26.55.66') {
	// 	return request.post('/gat/socket/intention', qs.stringify(param))
	// } else if (ip == "172.26.56.54") {
	// 	return request.post('/cysacyod/socket/intention', qs.stringify(param))
	// }else{
	// 	return request.post('/api/socket/intention', qs.stringify(param))
	// }
	
	let url = localStorage.getItem('AppInfoIp') + '/socket/intention'
	return request.post(url, qs.stringify(param))
};
export const getDevices = param => {
	// let ip = localStorage.getItem('AppInfoIp')
	// if (ip == '172.26.55.66') {
	// 	return request.post('/api/socket/getDevice', qs.stringify(param))
	// } else if (ip == "172.26.56.50") {
	// 	return request.post('/cysacyod/socket/getDevice', qs.stringify(param))
	// }else{
	// 	return request.post('/api/socket/getDevice', qs.stringify(param))
	// }

	// if (ip == '172.26.55.66') {
	// 	return request.post('/gat/socket/getDevice', qs.stringify(param))
	// } else if (ip == "172.26.56.54") {
	// 	return request.post('/cysacyod/socket/getDevice', qs.stringify(param))
	// }else{
	// 	return request.post('/api/socket/getDevice', qs.stringify(param))
	// }
	
	let url = localStorage.getItem('AppInfoIp') + '/socket/getDevice'
	return request.post(url, qs.stringify(param))
};

export const getWebAddress = param => {
	return request.post('/api/socket/getWebAddress', qs.stringify(param))
};
export const computerSpeed = param => {
	console.log(param, 'paramparamparamparam')
	return request.get('/api/record/computerSpeed?userId=' + param.userId)
};

export const blackListAdd = param => {
	return request.post('/api/blackList/add', qs.stringify(param))
};
export const getblackListVerify = param => {
	return request.post('/api/blackList/verify', qs.stringify(param))
};
export const getrecordUpload = param => {
	return request.post('/api/record/upload', qs.stringify(param))
};

export const socketforced = param => {
	return request.post('/api/socket/forced', qs.stringify(param))
};
export const downloadCustomerTemplate = param => {
	return request.post('/api/customer/downloadCustomerTemplate', qs.stringify(param), {
		responseType: 'blob'
	})
};